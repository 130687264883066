import { defineStore } from 'pinia';
import config from '../config.js';

export const useCaiChallengeMapStore = defineStore('cai-challenge-map-store', {
  state: () => {
    return {
      selectedFeature: null,
      selectedLocationChallenges: [],
    };
  },
  actions: {
    // TODO consider converting this store to a "setup" store where we can
    // create a watcher to automatically fetch challenges when the selected
    // location changes
    // https://pinia.vuejs.org/core-concepts/#Setup-Stores
    async fetchChallenges(geomHash) {
      // clear out previously-fetched challenges
      this.selectedLocationChallenges = [];

      const columns = [
        'challenge_id',
        'submission_id',
        'challenger_org',
        'status_code',
        'challenge_date',
        'challenge_type',
        'cai_type',
        'category_code',
        'entity_name',
        'location_id',
        'cai_id',
        'street_address',
        'city',
        'zip_code',
        'explanation',
      ];

      const res = await fetch(
        `${config.apiBaseUrl}/query/public_cai_challenges_mv?` +
          new URLSearchParams({
            columns: columns.join(','),
            filter: `geom_hash = '${geomHash}'`,
          }),
      );

      const json = await res.json();

      this.selectedLocationChallenges = json;
    },
  },
});
