<script setup>
// import { ChartBarIcon } from '@heroicons/vue/24/solid';
// import { MapIcon } from '@heroicons/vue/24/solid';

import { inject } from 'vue';
import { RouterLink, useRoute } from 'vue-router';

const $config = inject('$config');

const route = useRoute();

// TODO create computed for selected map view
</script>

<template>
  <nav class="my-4 flex flex-col gap-5 px-3 md:mt-2">
    <!-- <section>
          <ul class="flex flex-col gap-1">
            <li class="rounded-2xl p-3 hover:bg-zinc-800">
              <a href="#" class="flex gap-3">
                <div>Home</div>
              </a>
            </li>
          </ul>
        </section> -->
    <section>
      <header class="mb-2 text-sm uppercase tracking-wider text-zinc-300">
        Maps
      </header>

      <ul class="flex gap-2 md:flex-col">
        <li
          v-for="(mapViewDef, mapViewId) in $config.map.views"
          class="rounded-2xl hover:bg-gulf-blue-900"
          :class="{
            'bg-gulf-blue-600': route.params.selectedMapView === mapViewId,
          }"
        >
          <RouterLink
            :to="`/maps/${mapViewId}`"
            class="flex flex-col gap-1 px-3 py-2"
          >
            {{ mapViewDef.displayName }}

            <div v-if="mapViewDef.description">
              <div class="text-xs text-zinc-400">
                {{ mapViewDef.description }}
              </div>
            </div>
          </RouterLink>
        </li>
      </ul>
    </section>

    <section>
      <header class="mb-2 text-sm uppercase tracking-wider text-zinc-300">
        Trends
      </header>

      <ul class="flex gap-1 md:flex-col">
        <li
          class="rounded-2xl hover:bg-gulf-blue-900"
          :class="{
            'bg-gulf-blue-600': route.path === '/trends/overview',
          }"
        >
          <RouterLink :to="`/trends/overview`" class="flex gap-3 px-3 py-2">
            Overview
          </RouterLink>
        </li>
      </ul>
    </section>
  </nav>
</template>
