<script setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import AppNav from '@/components/AppNav.vue';
import AppFooter from '@/components/AppFooter.vue';
import AreaDetailsPanel from '@/components/details-panels/AreaDetailsPanel.vue';
import BslDetailsPanel from '@/components/details-panels/BslDetailsPanel.vue';
import CaiDetailsPanel from '@/components/details-panels/CaiDetailsPanel.vue';
import MapLegend from '@/components/MapLegend.vue';
import { useAreaChallengeMapStore } from './store/area-challenge-map';
import { useCaiChallengeMapStore } from './store/cai-challenge-map';
import { useChallengeMapStore } from './store/challenge-map';

// ROUTER
const route = useRoute();

// STORES
const areaChallengeMapStore = useAreaChallengeMapStore();
const caiChallengeMapStore = useCaiChallengeMapStore();
const challengeMapStore = useChallengeMapStore();

// COMPUTEDS
const selectedMapView = computed(() => route.params.selectedMapView);

// REFS
const { selectedFeature: selectedChallengeMapFeature } =
  storeToRefs(challengeMapStore);
const { selectedFeature: selectedCaiChallengeMapFeature } =
  storeToRefs(caiChallengeMapStore);
const { selectedBlockGroupId } = storeToRefs(areaChallengeMapStore);
</script>

<template>
  <div class="flex h-screen flex-col">
    <div class="bg-amber-200 p-3 text-black md:hidden">
      ⚠️ We're still optimizing this site for small screens. For a better
      experience, please use a laptop, desktop, or larger tablet.
    </div>
    <header
      class="flex flex-wrap items-center gap-x-[1rem] gap-y-[1rem] px-[1rem] py-[0.5rem] text-[2rem] text-white"
    >
      <img src="./assets/il-seal.webp" alt="" class="h-[48px]" />
      <img src="./assets/ibl-logo.webp" alt="" class="h-[48px]" />
      <div
        class="title-gradient flex self-center text-2xl font-bold md:text-[32px] md:leading-[36px]"
      >
        Illinois BEAD Challenge Dashboard
      </div>

      <div
        class="ml-2 mr-2 hidden h-fit items-center rounded-lg bg-amber-400 py-1 pl-2 pr-2 text-[15px] font-semibold text-gulf-blue-950 md:flex"
      >
        Adjudication Phase
      </div>
    </header>

    <main
      class="flex flex-col pt-3 text-white md:h-[calc(100vh-64px)] md:flex-1 md:flex-row"
    >
      <div
        class="md:flex md:w-[300px] md:flex-col md:justify-between md:overflow-y-auto md:px-3"
      >
        <AppNav />

        <div>
          <MapLegend class="hidden md:block" />
          <AppFooter class="hidden md:block" />
        </div>
      </div>

      <div
        class="mx-2 mb-4 rounded-lg shadow-md md:mx-0 md:mr-4 md:h-auto md:flex-1"
      >
        <RouterView />
      </div>

      <BslDetailsPanel
        class="md:w-[400px]"
        v-if="selectedMapView === 'challenges' && selectedChallengeMapFeature"
      />
      <AreaDetailsPanel
        class="md:w-[500px]"
        v-if="selectedMapView === 'area-challenges' && selectedBlockGroupId"
      />
      <CaiDetailsPanel
        class="md:w-[500px]"
        v-if="
          selectedMapView === 'cai-challenges' && selectedCaiChallengeMapFeature
        "
      />
    </main>

    <MapLegend class="md:hidden" />

    <AppFooter class="md:hidden" />
  </div>
</template>

<style>
/* TODO move these to tailwind somehow? 🤷 */
body {
  background: rgb(6, 12, 46);
  background: linear-gradient(
    153deg,
    rgba(6, 12, 46, 1) 0%,
    rgba(28, 43, 138, 1) 60%,
    rgba(43, 66, 213, 1) 100%
  );
}

.details-panel-background {
  background: linear-gradient(
      127.09deg,
      rgba(6, 11, 40, 0.94) 19.41%,
      rgba(10, 14, 35, 0.49) 76.65%
    )
    border-box;
}
</style>

<style scoped>
.title-gradient {
  background: linear-gradient(
    to bottom right,
    rgb(255, 255, 255),
    rgb(212, 232, 255)
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
