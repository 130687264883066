import { defineStore } from 'pinia';
import config from '../config.js';

export const useAreaChallengeMapStore = defineStore(
  'area-challenge-map-store',
  {
    state: () => {
      return {
        selectedBlockGroupId: null,
        selectedBlockGroupChallenges: [],
      };
    },
    actions: {
      // TODO consider converting this store to a "setup" store where we can
      // create a watcher to automatically fetch challenges when the selected
      // location changes
      // https://pinia.vuejs.org/core-concepts/#Setup-Stores
      async fetchAreaChallenges(blockGroupId) {
        this.selectedBlockGroupChallenges = [];

        const columns = [
          'block_group_id',
          'challenge_type',
          'provider_id',
          'technology',
          'status',
          'num_challenges',
        ];

        const res = await fetch(
          `${config.apiBaseUrl}/query/area_challenges_mv?` +
            new URLSearchParams({
              columns: columns.join(','),
              // TODO this field isn't currently indexed - does it perform OK?
              filter: `block_group_id = '${blockGroupId}'`,
            }),
        );

        const json = await res.json();

        this.selectedBlockGroupChallenges = json;
      },
    },
  },
);
