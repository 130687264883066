<script setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useAreaChallengeMapStore } from '@/store/area-challenge-map';
import {
  getNameForProviderId,
  getLabelForChallengeType,
  getLabelForTechCode,
} from '@/utils/ids-to-names';

// STORES
const areaChallengeMapStore = useAreaChallengeMapStore();

// REFS
const { selectedBlockGroupId, selectedBlockGroupChallenges: challenges } =
  storeToRefs(areaChallengeMapStore);

// COMPUTEDS
const challengesLength = computed(() => challenges.value.length);
// TODO delete anything "in progress" for area challenges, now that challenge
// phase is closed
const challengesInProgress = computed(() => {
  return challenges.value.filter((c) => c.num_challenges < 6);
});
const challengesComplete = computed(() => {
  return challenges.value.filter((c) => c.num_challenges >= 6);
});
</script>

<template>
  <div
    class="details-panel-background rounded-lg bg-zinc-800 p-4 shadow-md md:mb-4 md:mr-4 md:overflow-y-auto"
  >
    <h1 class="text-2xl font-semibold">
      Block Group {{ selectedBlockGroupId }}
    </h1>

    <!-- <hr class="my-4 border-zinc-500" /> -->

    <div v-if="challengesLength === 0">Loading challenges...</div>

    <div v-if="challengesLength > 0">
      <section class="mt-8" v-if="challengesInProgress.length > 0">
        <header class="mb-2 text-sm uppercase tracking-wider text-zinc-300">
          In Progress ({{ challengesInProgress.length }})
        </header>

        <table class="w-full table-auto text-left text-sm">
          <thead>
            <tr class="border-b border-b-zinc-600">
              <th scope="col" class="py-1">Type</th>
              <th scope="col">Provider</th>
              <th scope="col">Tech</th>
              <th scope="col"># Challenges</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="challenge in challengesInProgress"
              class="border-b border-b-zinc-600"
            >
              <!-- <th scope="row">1</th> -->
              <td class="py-1">
                {{ getLabelForChallengeType(challenge.challenge_type) }}
              </td>
              <td>{{ getNameForProviderId(challenge.provider_id) }}</td>
              <td>{{ getLabelForTechCode(challenge.technology) }}</td>
              <td>{{ challenge.num_challenges }}</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section class="mt-8" v-if="challengesComplete.length > 0">
        <header class="mb-2 text-sm uppercase tracking-wider text-zinc-300">
          Ready for Rebuttal ({{ challengesComplete.length }})
        </header>

        <table class="w-full table-auto text-left text-sm">
          <thead>
            <tr class="border-b border-b-zinc-600">
              <th scope="col" class="py-1">Type</th>
              <th scope="col">Provider</th>
              <th scope="col">Tech</th>
              <th scope="col"># Challenges</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="challenge in challengesComplete"
              class="border-b border-b-zinc-600"
            >
              <!-- <th scope="row">1</th> -->
              <td class="py-1">
                {{ getLabelForChallengeType(challenge.challenge_type) }}
              </td>
              <td>{{ getNameForProviderId(challenge.provider_id) }}</td>
              <td>{{ getLabelForTechCode(challenge.technology) }}</td>
              <td>{{ challenge.num_challenges }}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  </div>
</template>
