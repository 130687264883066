<script setup>
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useTrendsStore } from '@/store/trends';
import { FlagIcon, UserIcon, MapPinIcon } from '@heroicons/vue/24/solid';

// STORES
const trendsStore = useTrendsStore();

// REFS
const { stats } = storeToRefs(trendsStore);

onMounted(() => {
  if (!stats.value) {
    trendsStore.fetchStats();
  }
});
</script>

<template>
  <div class="md:mx-auto md:w-[900px]">
    <h1 class="text-base font-semibold">Overview</h1>

    <hr class="mb-6 mt-4 border-zinc-600" />

    <div v-if="!stats">Loading...</div>

    <div class="grid gap-x-10 gap-y-6 md:grid-cols-2" v-if="stats">
      <div
        class="rounded-md bg-gulf-blue-950 p-4 px-8 shadow-md hover:bg-gulf-blue-900"
      >
        <header class="mb-3 text-sm text-zinc-300">
          <FlagIcon class="mr-1 inline h-4 w-4" />
          Challenges
        </header>
        <div class="text-3xl font-semibold">
          {{ parseInt(stats.challenges_count).toLocaleString() }}
        </div>
      </div>
      <div
        class="rounded-md bg-gulf-blue-950 p-4 px-8 shadow-md hover:bg-gulf-blue-900"
      >
        <header class="mb-3 text-sm text-zinc-300">
          <UserIcon class="mr-1 inline h-4 w-4" />
          Registered Challengers
        </header>
        <div class="text-3xl font-semibold">
          {{ parseInt(stats.users_count).toLocaleString() }}
        </div>
      </div>
      <div
        class="rounded-md bg-gulf-blue-950 p-4 px-8 shadow-md hover:bg-gulf-blue-900"
      >
        <header class="mb-3 text-sm text-zinc-300">
          <MapPinIcon class="mr-1 inline h-4 w-4" />
          Unique Locations and CAIs
        </header>
        <div class="text-3xl font-semibold">
          {{ parseInt(stats.locations_and_cais_unique_count).toLocaleString() }}
        </div>
      </div>
    </div>
  </div>
</template>
