import { defineStore } from 'pinia';
import config from '../config.js';

export const useChallengeMapStore = defineStore('challenge-map-store', {
  state: () => {
    return {
      selectedFeature: null,
      selectedLocationChallenges: [],
      selectedProviderId: '',
      submissionIdSearchString: '',
    };
  },
  actions: {
    // TODO consider converting this store to a "setup" store where we can
    // create a watcher to automatically fetch challenges when the selected
    // location changes
    // https://pinia.vuejs.org/core-concepts/#Setup-Stores
    async fetchChallenges(locationId, caiId) {
      // clear out previously-fetched challenges
      this.selectedLocationChallenges = [];

      const columns = [
        'challenge_id',
        'submission_id',
        'challenger_org',
        'challenge_type',
        'challenge_date',
        'status_code',
        'provider_id',
        'technology',
        'residential_or_business',
        'unit',
        'reason_code',
      ];

      const queryField = caiId ? 'cai_id' : 'location_id';
      const queryVal = caiId || locationId;

      const res = await fetch(
        `${config.apiBaseUrl}/query/public_service_challenges_mv?` +
          new URLSearchParams({
            columns: columns.join(','),
            filter: `${queryField} = ${queryVal}`,
          }),
      );

      const json = await res.json();

      this.selectedLocationChallenges = json;
    },
  },
});
