<script setup>
import { computed } from 'vue';
import {
  getLabelForTechCode,
  getNameForProviderId,
  getLabelForChallengeType,
} from '@/utils/ids-to-names';

// TODO use store?
const props = defineProps(['feature']);

// COMPUTEDS
const featureProps = computed(() => props.feature.properties);
const isSingle = computed(() => featureProps.num_challenges == 1);
const locationType = computed(() => {
  return featureProps.value.cai_id ? 'cai' : 'bsl';
});
const providerNames = computed(() => {
  const { provider_ids: providerIds } = featureProps.value;
  // TODO sort
  return providerIds.split('|').map(getNameForProviderId).join(', ');
});
const technologyLabels = computed(() => {
  const { technologies } = featureProps.value;
  // TODO sort
  return technologies.split('|').map(getLabelForTechCode).join(', ');
});
const challengeTypeLabels = computed(() => {
  const { challenge_types: challengeTypes } = featureProps.value;
  // TODO sort
  return challengeTypes.split('|').map(getLabelForChallengeType).join(', ');
});
</script>

<template>
  <div v-if="feature" class="text-black">
    <h1 class="text-base font-semibold">
      {{ featureProps.street_address }}
    </h1>
    <div>{{ featureProps.city }}, IL {{ featureProps.zip_code }}</div>
    <div v-if="locationType === 'bsl'">
      Unit Count: {{ featureProps.unit_count }}
    </div>
    <div v-if="locationType === 'cai'">
      {{ featureProps.cai_entity_name }}

      <span
        class="ml-1 rounded border border-green-400 bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800"
      >
        CAI
      </span>
    </div>

    <!-- TODO rethink if/how to communicate status for > 1 challenge -->
    <!-- <div
      class="my-1 w-fit rounded-md bg-amber-300 p-1 px-2 text-[0.73rem] font-semibold text-black"
    >
      Pending Review
    </div> -->

    <hr class="mb-2 mt-3" />

    <div>
      <p class="mb-2 text-sm" v-if="featureProps.num_challenges > 1">
        There are <strong>{{ featureProps.num_challenges }} challenges</strong>
        here.
      </p>

      <table class="table-auto">
        <tbody class="text-left">
          <tr>
            <th class="pr-2">{{ 'Type' + (!isSingle && 's') }}</th>
            <td>{{ challengeTypeLabels }}</td>
          </tr>
          <tr>
            <th class="pr-2">Providers</th>
            <td>{{ providerNames }}</td>
          </tr>
          <tr>
            <th class="pr-2">Technologies</th>
            <td>{{ technologyLabels }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <hr class="my-2" />

    <p
      class="animate-pulse text-center text-sm font-semibold text-gulf-blue-900"
    >
      Click for Details
    </p>
  </div>
</template>
