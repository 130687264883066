import { createRouter, createWebHashHistory } from 'vue-router';
import MapView from '../views/MapView.vue';
import TrendsView from '../views/TrendsView.vue';

const routes = [
  {
    path: '/',
    redirect: '/maps/challenges',
  },
  // TODO redirect /maps to /maps/overview?
  {
    path: '/maps/:selectedMapView',
    component: MapView,
  },
  {
    path: '/trends/overview',
    component: TrendsView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
