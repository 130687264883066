import PROVIDERS from '@/constants/providers.json';

// NOTE / HACK: this assumes unique provider names (which we happen to have)
export function getFccIdForProviderName(providerName) {
  const matches = PROVIDERS.filter((p) => p.name == providerName);

  if (matches.length === 0) {
    return;
  }

  return matches[0].fcc_provider_id;
}
