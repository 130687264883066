import { defineStore } from 'pinia';
import config from '../config.js';

export const useTrendsStore = defineStore('trends-store', {
  state: () => {
    return {
      stats: null,
    };
  },
  actions: {
    async fetchStats() {
      const res = await fetch(
        `${config.apiBaseUrl}/query/public_summary_stats_mv?` +
          new URLSearchParams({ columns: '*' }),
      );

      const json = await res.json();

      // reshape key-value rows to object
      const statsObj = json.reduce((acc, row) => {
        acc[row.key] = row.value;
        return acc;
      }, {});

      this.stats = statsObj;
    },
  },
});
