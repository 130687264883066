<script setup>
import { computed } from 'vue';

const props = defineProps(['feature']);

const featureProps = computed(() => props.feature.properties);
</script>

<template>
  <div v-if="feature" class="text-black">
    <div>
      <h1 class="text-base font-semibold">
        Block Group {{ featureProps.block_group_id }}
      </h1>
    </div>

    <!-- <div
      class="my-1 w-fit rounded-md bg-amber-300 p-1 px-2 text-[0.73rem] font-semibold text-black"
    >
      Pending Review
    </div> -->

    <hr class="mb-2 mt-3" />

    <div>
      <p class="mb-2 text-sm" v-if="featureProps.num_area_challenges">
        There
        <!-- TODO clean this up; ternary operators weren't working -->
        <span v-if="featureProps.num_area_challenges == 1">is</span>
        <span v-if="featureProps.num_area_challenges > 1">are</span>
        <strong
          >&nbsp;
          {{ featureProps.num_area_challenges }}
          area
          <span v-if="featureProps.num_area_challenges == 1">challenge</span>
          <span v-if="featureProps.num_area_challenges > 1">challenges</span>
        </strong>
        in this block group.
      </p>

      <hr class="my-2" />

      <p
        class="animate-pulse text-center text-sm font-semibold text-gulf-blue-900"
      >
        Click for Details
      </p>
    </div>
  </div>
</template>
