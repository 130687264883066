import PROVIDERS from '@/constants/providers.json';
import TECHNOLOGIES from '@/constants/technologies.json';
import CHALLENGE_TYPES from '@/constants/challenge-types.json';
import CHALLENGE_STATUSES from '@/constants/challenge-statuses.json';
import BUILDING_TYPES from '@/constants/building-types.json';

// TODO these are almost all the same... just the keys differ sometimes;
// refactor?
export function getNameForProviderId(providerId) {
  const matches = PROVIDERS.filter((p) => p.fcc_provider_id == providerId);

  if (matches.length === 0) {
    return;
  }

  return matches[0].name;
}

export function getLabelForTechCode(techCode) {
  const matches = TECHNOLOGIES.filter((t) => t.code == techCode);

  if (matches.length === 0) {
    return;
  }

  return matches[0].label;
}

export function getLabelForChallengeType(challengeType) {
  const matches = CHALLENGE_TYPES.filter((t) => t.code == challengeType);

  if (matches.length === 0) {
    return;
  }

  return matches[0].label;
}

export function getLabelForChallengeStatus(statusCode) {
  const matches = CHALLENGE_STATUSES.filter((s) => s.code == statusCode);

  if (matches.length === 0) {
    return;
  }

  return matches[0].label;
}

export function getLabelForBuildingTypeCode(buildingTypeCode) {
  const matches = BUILDING_TYPES.filter((t) => t.code == buildingTypeCode);

  if (matches.length === 0) {
    return;
  }

  return matches[0].label;
}
