import './assets/main.css';

import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import config from './config.js';
import router from './router/index.js';
// import { useStore } from './store/index.js';
import App from './App.vue';

const app = createApp(App);

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://7fc27cd252709661600abb869b863832@o4506893373603840.ingest.us.sentry.io/4506893380943872',
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
      }),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(createPinia());
app.use(router);

app.provide('$config', config);

app.mount('#app');

// kick off initial data-loading
// const store = useStore();
// store.fetchLastUpdatedTime();
