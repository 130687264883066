<script setup>
import { GeocodingControl } from '@maptiler/geocoding-control/maplibregl';
import turfCentroid from '@turf/centroid';
import maplibregl from 'maplibre-gl';
import { storeToRefs } from 'pinia';
import { Protocol as PmtilesProtocol } from 'pmtiles';
import { computed, inject, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import ServiceChallengePopup from '@/components/map-popups/ServiceChallengePopup.vue';
import CaiChallengePopup from '@/components/map-popups/CaiChallengePopup.vue';
import AreaChallengePopup from '@/components/map-popups/AreaChallengePopup.vue';
import { useAreaChallengeMapStore } from '@/store/area-challenge-map';
import { useCaiChallengeMapStore } from '@/store/cai-challenge-map';
import { useChallengeMapStore } from '@/store/challenge-map';

import 'maplibre-gl/dist/maplibre-gl.css';
import '@maptiler/geocoding-control/style.css';

const $config = inject('$config');

// set up pmtiles
maplibregl.addProtocol('pmtiles', new PmtilesProtocol().tile);

let map;

// STORES
const areaChallengeMapStore = useAreaChallengeMapStore();
const caiChallengeMapStore = useCaiChallengeMapStore();
const challengeMapStore = useChallengeMapStore();

// REFS
const currentZoom = ref(0);
const popupEl = ref(null);
const popupHoverFeature = ref(null);
const { selectedProviderId, submissionIdSearchString } =
  storeToRefs(challengeMapStore);

// not sure exactly what to classify this as but it gets used in the computed
// below
const route = useRoute();

// COMPUTEDS
const selectedMapView = computed(() => {
  // TODO does useRoute get re-evaluated over and over?
  return route.params.selectedMapView;
});

// WATCHERS
watch(selectedMapView, (nextSelectedMapView, prevSelectedMapView) => {
  // unhide the next layer
  map.setLayoutProperty(nextSelectedMapView, 'visibility', 'visible');

  // hide prev layer
  map.setLayoutProperty(prevSelectedMapView, 'visibility', 'none');
});

watch(
  [selectedProviderId, submissionIdSearchString],
  handleServiceChallengeFilterUpdate,
);

// helper to update service challenge filter
function handleServiceChallengeFilterUpdate() {
  const subfilters = [];

  // provider id
  if (selectedProviderId.value.length > 0) {
    subfilters.push(['in', selectedProviderId.value, ['get', 'provider_ids']]);
  }

  // submission id
  if (submissionIdSearchString.value.length > 0) {
    subfilters.push([
      'in',
      `|${submissionIdSearchString.value}|`,
      ['get', 'submission_ids'],
    ]);
  }

  const nextFilter = ['all', ...subfilters];
  map.setFilter('challenges', nextFilter);
}

/**
 * BEGIN MAP
 */

onMounted(() => {
  try {
    map = new maplibregl.Map({
      ...$config.map.options,
      container: 'map',
    });
  } catch (e) {
    console.error('initialize map - error -', e);

    alert(
      `There was an issue loading the map. Please try using another web browser or device, if you have access to one.\n\n
      If you're still experiencing this issue, please reach out to broadband@illinois.gov for assistance.`,
    );
  }

  currentZoom.value = map.getZoom();

  // add navigation control
  map.addControl(new maplibregl.NavigationControl());

  // add geocoder
  // https://github.com/maptiler/maptiler-geocoding-control#example-for-maplibre-gl-js-using-module-bundler
  map.addControl(
    new GeocodingControl({
      apiKey: $config.maptilerApiKey,
      maplibregl,
      marker: false,
    }),
    'top-left',
  );

  map.on('load', () => {
    // add sources
    for (const [sourceId, sourceDef] of Object.entries($config.map.sources)) {
      map.addSource(sourceId, { ...sourceDef });
    }

    // find the index of the first symbol layer in the map style
    // https://docs.mapbox.com/mapbox-gl-js/example/geojson-layer-in-stack/
    const layers = map.getStyle().layers;
    let firstSymbolId;
    for (const layer of layers) {
      if (layer.type === 'symbol') {
        firstSymbolId = layer.id;
        break;
      }
    }

    // add layers
    for (const [mapViewId, mapViewDef] of Object.entries($config.map.views)) {
      const layerDef = { ...mapViewDef.layer };

      if (mapViewId !== selectedMapView.value) {
        layerDef.layout = {
          visibility: 'none',
        };
      }

      map.addLayer(layerDef, firstSymbolId);
    }

    // HACK glowy state boundary
    map.addSource('state-boundary', {
      type: 'vector',
      tiles: [
        `https://il-bead-dirt-app-5bcjd.ondigitalocean.app/v1/mvt/state_boundary/{z}/{x}/{y}`,
      ],
    });
    map.addLayer(
      {
        id: 'state-boundary-glow',
        type: 'line',
        source: 'state-boundary',
        'source-layer': 'state_boundary',
        paint: {
          'line-color': '#1d33b6',
          // prettier-ignore
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            // zoom is 5 (or less)
            5, 8,
            // zoom is 10 (or greater)
            15, 30,
          ],
          // prettier-ignore
          'line-blur': [
            'interpolate',
            ['linear'],
            ['zoom'],
            // zoom is 5 (or less) -> circle radius will be 1px
            5, 16,
            // zoom is 10 (or greater) -> circle radius will be 5px
            15, 60,
          ],
        },
      },
      firstSymbolId,
    );
    map.addLayer(
      {
        id: 'state-boundary',
        type: 'line',
        source: 'state-boundary',
        'source-layer': 'state_boundary',
        paint: {
          'line-color': '#6e99ff',
          // prettier-ignore
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            // zoom is 5 (or less)
            5, 1,
            // zoom is 10 (or greater)
            17, 2,
          ],
        },
      },
      firstSymbolId,
    );
  });

  /**
   * POPUPS
   */

  // cache zoom level (we check this before showing the popup)
  map.on('zoomend', () => {
    const nextZoom = map.getZoom();
    currentZoom.value = nextZoom;
  });

  // POPUP

  const popup = new maplibregl.Popup({
    closeButton: false,
    closeOnClick: false,
  }).setDOMContent(popupEl.value);

  // LISTEN FOR HOVER ON / OFF

  // challenges
  map.on('mouseenter', 'challenges', (e) => {
    // only show the popup within a certain zoom level
    if (currentZoom.value < 8) return;

    // adapted from https://maplibre.org/maplibre-gl-js/docs/examples/popup-on-hover/
    map.getCanvas().style.cursor = 'pointer';

    const { features } = e;
    const [firstFeature] = features;
    const { coordinates } = firstFeature.geometry;

    popupHoverFeature.value = firstFeature;

    popup.setLngLat(coordinates).addTo(map);
  });

  map.on('mouseleave', 'challenges', () => {
    map.getCanvas().style.cursor = '';
    popup.remove();
    popupHoverFeature.value = null;
  });

  // TODO try to dedupe this code with the service challenge hover stuff above
  // challenges
  map.on('mouseenter', 'cai-challenges', (e) => {
    // only show the popup within a certain zoom level
    if (currentZoom.value < 8) return;

    // adapted from https://maplibre.org/maplibre-gl-js/docs/examples/popup-on-hover/
    map.getCanvas().style.cursor = 'pointer';

    const { features } = e;
    const [firstFeature] = features;
    const { coordinates } = firstFeature.geometry;

    popupHoverFeature.value = firstFeature;

    popup.setLngLat(coordinates).addTo(map);
  });

  map.on('mouseleave', 'cai-challenges', () => {
    map.getCanvas().style.cursor = '';
    popup.remove();
    popupHoverFeature.value = null;
  });

  // TODO try to dedupe this code with the service challenge hover stuff above
  // challenges
  map.on('mouseenter', 'area-challenges', (e) => {
    // only show the popup within a certain zoom level
    if (currentZoom.value < 8) return;

    // adapted from https://maplibre.org/maplibre-gl-js/docs/examples/popup-on-hover/
    map.getCanvas().style.cursor = 'pointer';

    const { features } = e;
    const [firstFeature] = features;
    popupHoverFeature.value = firstFeature;

    const { geometry } = firstFeature;
    const centroid = turfCentroid(geometry);
    const lngLat = centroid.geometry.coordinates;

    popup.setLngLat(lngLat).addTo(map);
  });

  map.on('mouseleave', 'area-challenges', () => {
    map.getCanvas().style.cursor = '';
    popup.remove();
    popupHoverFeature.value = null;
  });

  // LISTEN FOR MAP CLICKS
  map.on('click', 'challenges', (e) => {
    // put feature in store; should only ever be one feature because they've
    // been deduped spatially
    const selectedFeature = e.features[0].properties;

    challengeMapStore.selectedFeature = { ...selectedFeature };

    // fetch challenges
    challengeMapStore.fetchChallenges(
      selectedFeature.location_id,
      selectedFeature.cai_id,
    );
  });

  map.on('click', 'cai-challenges', (e) => {
    // put feature in store; should only ever be one feature because they've
    // been deduped spatially
    const selectedFeature = e.features[0].properties;

    caiChallengeMapStore.selectedFeature = { ...selectedFeature };

    // fetch challenges
    caiChallengeMapStore.fetchChallenges(selectedFeature.geom_hash);
  });

  map.on('click', 'area-challenges', (e) => {
    const { properties } = e.features[0];
    const blockGroupId = properties.block_group_id;

    areaChallengeMapStore.selectedBlockGroupId = blockGroupId;
    areaChallengeMapStore.fetchAreaChallenges(blockGroupId);
  });
});
</script>

<template>
  <div id="map" class="h-[500px] rounded-xl md:h-full" />

  <!-- POPUPS -->
  <!--
    HACK i tried setting ref on the serviceChallengePopup itself but it was null so
    wrapping it in a regular div is a workaround
  -->
  <div ref="popupEl">
    <ServiceChallengePopup
      :feature="popupHoverFeature"
      v-if="selectedMapView === 'challenges'"
    />
    <CaiChallengePopup
      :feature="popupHoverFeature"
      v-if="selectedMapView === 'cai-challenges'"
    />
    <AreaChallengePopup
      :feature="popupHoverFeature"
      v-if="selectedMapView === 'area-challenges'"
    />
  </div>
</template>
