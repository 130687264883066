<script setup>
import { computed } from 'vue';
import { getLabelForChallengeType } from '@/utils/ids-to-names';

const props = defineProps(['feature']);

// COMPUTEDS
const featureProps = computed(() => props.feature.properties);
const challengeTypeLabel = computed(() => {
  return getLabelForChallengeType(featureProps.value.newest_challenge_type);
});
</script>

<template>
  <div v-if="feature" class="text-black">
    <div v-if="featureProps.newest_street_address">
      <h1 class="text-base font-semibold">
        {{ featureProps.newest_street_address }}
      </h1>
      <div>
        {{ featureProps.newest_city }}, IL {{ featureProps.newest_zip_code }}
      </div>
    </div>

    <div v-if="!featureProps.newest_street_address">
      <h1 class="text-base font-semibold">No Address</h1>
    </div>

    <hr class="mb-2 mt-3" />

    <div>
      <p class="mb-2 text-sm" v-if="featureProps.num_challenges > 1">
        There are {{ featureProps.num_challenges_at_location }} challenges here.
        Showing the most recent.
      </p>

      <table class="table-auto">
        <tbody class="text-left">
          <tr>
            <th class="pr-2">Challenge Type</th>
            <td>{{ challengeTypeLabel }}</td>
          </tr>
          <tr>
            <th class="pr-2">Entity Name</th>
            <td>{{ featureProps.newest_entity_name }}</td>
          </tr>
          <tr>
            <th class="pr-2">CAI Type</th>
            <!-- TODO get the label for this -->
            <td>{{ featureProps.newest_cai_type }}</td>
          </tr>
          <tr v-if="featureProps.newest_cai_id">
            <th class="pr-2">CAI ID</th>
            <td>{{ featureProps.newest_cai_id }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <hr class="my-2" />

    <p class="animate-pulse text-center text-sm font-semibold">
      Click for Details
    </p>
  </div>
</template>
