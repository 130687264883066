<script setup>
import { storeToRefs } from 'pinia';
import { useChallengeMapStore } from '@/store/challenge-map.js';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/vue/24/solid';
import { computed, ref, watch } from 'vue';
import {
  getNameForProviderId,
  getLabelForBuildingTypeCode,
  getLabelForChallengeStatus,
  getLabelForChallengeType,
  getLabelForTechCode,
} from '@/utils/ids-to-names';

const challengeMapStore = useChallengeMapStore();

const {
  selectedFeature: selectedMapFeature,
  selectedLocationChallenges: challenges,
} = storeToRefs(challengeMapStore);

// REFS
const currentChallengeIndex = ref(0);

// COMPUTEDS
const currentChallenge = computed(
  () => challenges.value[currentChallengeIndex.value],
);
const challengesLength = computed(() => challenges.value.length);
// TODO sort challenges by date?

// WATCHERS
// reset current challenge index when user selects a new location
watch(selectedMapFeature, () => {
  currentChallengeIndex.value = 0;
});
</script>

<template>
  <div
    class="details-panel-background rounded-lg bg-zinc-800 p-4 shadow-md md:mb-4 md:mr-4 md:overflow-y-auto"
  >
    <h1 class="text-2xl font-semibold">
      {{ selectedMapFeature.street_address }}
    </h1>
    <div class="mb-3">
      {{ selectedMapFeature.city }}, IL {{ selectedMapFeature.zip_code }}
      <span
        class="ml-1 rounded border border-green-400 bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800"
        v-if="selectedMapFeature.cai_id"
      >
        CAI
      </span>

      <span
        v-if="selectedMapFeature.unit_count > 1"
        class="me-2 ml-2 rounded bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800 dark:bg-pink-900 dark:text-pink-300"
        >MDU</span
      >
    </div>

    <hr class="my-4 border-zinc-500" />

    <section v-if="selectedMapFeature.cai_id">
      <header class="mb-2 text-sm uppercase tracking-wider text-zinc-300">
        CAI Details
      </header>

      <table class="text-left text-sm">
        <tr>
          <th class="py-1 pr-4">CAI ID</th>
          <td>{{ selectedMapFeature.cai_id }}</td>
        </tr>
        <tr>
          <th class="py-1 pr-4">Name</th>
          <td>{{ selectedMapFeature.cai_entity_name }}</td>
        </tr>
      </table>
    </section>

    <section v-if="selectedMapFeature.location_id">
      <header class="mb-2 text-sm uppercase tracking-wider text-zinc-300">
        Location Details
      </header>

      <p
        class="my-4 rounded border-l-4 border-l-gulf-blue-600 bg-zinc-800 p-2 text-xs"
      >
        This information comes from the
        <a
          href="https://help.bdc.fcc.gov/hc/en-us/articles/16842264428059-About-the-Fabric-What-a-Broadband-Serviceable-Location-BSL-Is-and-Is-Not"
          target="_blank"
          class="text-gulf-blue-400"
        >
          FCC Broadband Serviceable Location Fabric</a
        >.
      </p>

      <table class="text-left text-sm">
        <tr>
          <th class="py-1 pr-4">Location ID</th>
          <td>{{ selectedMapFeature.location_id }}</td>
        </tr>
        <tr>
          <th class="py-1 pr-4">Unit Count</th>
          <td>
            {{ selectedMapFeature.unit_count }}
          </td>
        </tr>
        <tr>
          <th class="py-1 pr-4">Building Type Code</th>
          <td>
            {{
              getLabelForBuildingTypeCode(selectedMapFeature.building_type_code)
            }}
          </td>
        </tr>
      </table>
    </section>

    <hr class="my-4 border-zinc-500" />

    <section>
      <!-- <header class="mb-2 mt-6 text-sm uppercase tracking-wider text-zinc-300">
        Challenges
      </header> -->

      <div v-if="challengesLength === 0">Loading challenges...</div>

      <div v-if="challengesLength > 0">
        <h2 class="mt-4 text-lg font-semibold" v-if="challengesLength === 1">
          There is 1 challenge at this location.
        </h2>
        <h2 class="mt-6 text-lg font-semibold" v-if="challengesLength > 1">
          Showing challenge
          {{ currentChallengeIndex + 1 }} of {{ challengesLength }} at this
          location:
        </h2>

        <!-- TODO use `apply` for the align-top and any other global table styles -->
        <table class="mt-4 table-auto text-left text-sm">
          <tr class="align-top">
            <th class="py-1 pr-4">Challenge Type</th>
            <td>
              {{ getLabelForChallengeType(currentChallenge.challenge_type) }}
            </td>
          </tr>
          <tr class="align-top">
            <th class="py-1 pr-4">Status</th>
            <td>
              {{ getLabelForChallengeStatus(currentChallenge.status_code) }}
            </td>
          </tr>
          <tr class="align-top">
            <th class="py-1 pr-4">Challenger</th>
            <td>{{ currentChallenge.challenger_org }}</td>
          </tr>
          <tr class="align-top">
            <th class="py-1 pr-4">Provider</th>
            <td>{{ getNameForProviderId(currentChallenge.provider_id) }}</td>
          </tr>
          <tr class="align-top">
            <th class="py-1 pr-4">Technology</th>
            <td>{{ getLabelForTechCode(currentChallenge.technology) }}</td>
          </tr>
          <tr class="align-top">
            <th class="py-1 pr-4">Residential/Business</th>
            <td>
              <!-- TODO factor this out -->
              {{
                { R: 'Residential', B: 'Business', X: 'Mixed Use' }[
                  currentChallenge.residential_or_business
                ]
              }}
            </td>
          </tr>
          <tr class="align-top">
            <th class="py-1 pr-4">Date</th>
            <td>
              {{ new Date(currentChallenge.challenge_date).toLocaleString() }}
            </td>
          </tr>
          <tr class="align-top">
            <th class="py-1 pr-4">Challenge ID</th>
            <td>{{ currentChallenge.challenge_id }}</td>
          </tr>
          <tr class="align-top">
            <th class="py-1 pr-4">Submission ID</th>
            <td>{{ currentChallenge.submission_id }}</td>
          </tr>
        </table>

        <div
          class="mt-4 flex place-content-between"
          v-if="challengesLength > 1"
        >
          <button
            class="flex items-center rounded-md bg-gulf-blue-800 px-4 py-2 text-white hover:bg-gulf-blue-600"
            @click="currentChallengeIndex--"
            :class="{ invisible: currentChallengeIndex === 0 }"
          >
            <ArrowLeftIcon class="mr-2 inline h-4 w-4" />
            Back
          </button>
          <button
            class="flex items-center rounded-md bg-gulf-blue-800 px-4 py-2 text-white hover:bg-gulf-blue-600"
            @click="currentChallengeIndex++"
            :class="{
              invisible: currentChallengeIndex === challengesLength - 1,
            }"
          >
            Next
            <ArrowRightIcon class="ml-2 inline h-4 w-4" />
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
