/**
 * GLOBAL APP CONFIG
 */

const API_BASE_URL = 'https://il-bead-dirt-app-5bcjd.ondigitalocean.app/v1';
const MAPTILER_API_KEY = '8jz7p2F1hzWGWraWg5pz';
// columns that get fetched with map tiles
const MAP_SOURCE_COLUMNS = {
  // TODO these shouldn't be used now that these are tiled now?
  // challenges: [
  //   'location_id',
  //   'cai_id',
  //   'cai_entity_name',
  //   'street_address',
  //   'city',
  //   'zip_code',
  //   'unit_count',
  //   'building_type_code',
  //   'num_challenges',
  //   'challenge_types',
  //   'provider_ids',
  //   'technologies',
  //   'submission_ids',
  //   'challenge_statuses',
  // ],
  'cai-challenges': [
    'num_challenges_at_location',
    'newest_challenge_type',
    'newest_cai_type',
    'newest_entity_name',
    // 'newest_location_id',
    'newest_cai_id',
    'newest_street_address',
    'newest_city',
    'newest_zip_code',
    'geom_hash',
  ],
  'area-challenges': ['block_group_id', 'num_area_challenges', 'provider_ids'],
};

// interpolated-zoom radius function for points
// prettier-ignore
const pointCircleRadius = [
  'interpolate', ['linear'], ['zoom'],
  7, 1.8,
  12, 3.5,
  17, 5.5,
];

const config = {
  apiBaseUrl: API_BASE_URL,
  maptilerApiKey: MAPTILER_API_KEY,
  map: {
    /**
     * OPTIONS
     */

    options: {
      center: [-89.2, 39.9],
      zoom: 6,
      minZoom: 5.75,
      maxZoom: 17,
      style: `https://api.maptiler.com/maps/backdrop-dark/style.json?key=${MAPTILER_API_KEY}`,
    },

    /**
     * SOURCES
     */

    sources: {
      // note: we call these "service challenges" on the back end to
      // differentiate them from cai challenges; use just "challenges" here
      // so we're not introducing new terminology to users
      challenges: {
        type: 'vector',
        // // dirt
        // tiles: [
        //   // local
        //   // 'http://localhost:3000/v1/mvt/dash_bsl_challenge_summary_mv/{z}/{x}/{y}?columns=*',
        //   // digitalocean
        //   `${API_BASE_URL}/mvt/public_service_challenge_map_points_mv/{z}/{x}/{y}?columns=${MAP_SOURCE_COLUMNS['challenges'].join(',')}`,
        // ],
        // // pmtiles
        url: 'pmtiles://https://il-bead-static.nyc3.digitaloceanspaces.com/map-tiles/service_challenge_points_20240419_183017.pmtiles',
      },
      'cai-challenges': {
        type: 'vector',
        tiles: [
          // TODO remove geom column and anything else we don't need
          `${API_BASE_URL}/mvt/public_cai_challenge_map_points_mv/{z}/{x}/{y}?columns=${MAP_SOURCE_COLUMNS['cai-challenges'].join(',')}`,
        ],
      },
      'area-challenges': {
        type: 'vector',
        tiles: [
          // TODO remove geom column and anything else we don't need
          `${API_BASE_URL}/mvt/public_area_challenge_map_polys_mv/{z}/{x}/{y}?columns=${MAP_SOURCE_COLUMNS['area-challenges'].join(',')}`,
        ],
      },
      // TODO area challenge block groups
      // TODO mdus
    },

    /**
     * VIEWS
     *
     * a map "view" is a bundle of layers and other options; they are 1:1 with
     * the items in the left nav under "maps".
     *
     * - layer: this is the exact maplibre definition for the clickable layer
     *   for the view (e.g. bsls, cais, block groups)
     */

    views: {
      // this key aliases service-challenges => challenges to hide our internal
      // jargon from users (shows up in the routing)
      challenges: {
        displayName: 'Challenges',
        layer: {
          id: 'challenges',
          source: 'challenges',
          'source-layer': 'service-challenge-points',
          type: 'circle',
          paint: {
            'circle-color': 'rgb(252, 211, 77)',
            'circle-radius': pointCircleRadius,
          },
        },
      },
      'cai-challenges': {
        displayName: 'CAI Challenges',
        description: 'Type C and R challenges',
        layer: {
          id: 'cai-challenges',
          // TODO change to cai challenges
          source: 'cai-challenges',
          'source-layer': 'public_cai_challenge_map_points_mv',
          type: 'circle',
          paint: {
            'circle-color': '#46FF27',
            'circle-radius': pointCircleRadius,
          },
        },
      },
      'area-challenges': {
        displayName: 'Area Challenges',
        layer: {
          id: 'area-challenges',
          source: 'area-challenges',
          'source-layer': 'public_area_challenge_map_polys_mv',
          type: 'fill',
          paint: {
            'fill-color': 'rgba(200, 0, 255, 1.0)',
            'fill-opacity': 0.6,
          },
          // layout: {
          //   visibility: 'none',
          // },
        },
      },
    },
  },
};

export default config;
