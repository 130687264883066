<!--
  TODO this might benefit from a refactor/rename; it started out as a map legend
  but now has more input-like things, like filters
 -->

<script setup>
import { FunnelIcon, MapIcon } from '@heroicons/vue/24/solid';
import { storeToRefs } from 'pinia';
import {
  // TODO / FUTURE: see todo below re: using config for legend
  // inject,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { useChallengeMapStore } from '@/store/challenge-map.js';
import { getFccIdForProviderName } from '@/utils/names-to-ids';
import PROVIDERS from '@/constants/providers.json';

// TODO define legend declaratively in config
// const $config = inject('$config');

const route = useRoute();

// STORES
const challengeMapStore = useChallengeMapStore();

// REFS
const { submissionIdSearchString } = storeToRefs(challengeMapStore);

// MODELS
const selectedProviderName = defineModel();

// WATCHERS
watch(selectedProviderName, (nextSelectedProviderName) => {
  const nextProviderId = getFccIdForProviderName(nextSelectedProviderName);

  if (nextProviderId) {
    challengeMapStore.selectedProviderId = nextProviderId;
  } else {
    challengeMapStore.selectedProviderId = '';
  }
});
</script>

<template>
  <transition
    enter-active-class="duration-300 ease-out"
    enter-from-class="transform opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-200 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="transform opacity-0"
  >
    <!-- TODO make dynamic based on selected map view -->
    <div
      v-if="
        ['challenges', 'area-challenges'].includes(route.params.selectedMapView)
      "
      class="mx-2 flex flex-col space-y-10 rounded-lg border-[1px] border-zinc-700 bg-zinc-800 p-3 text-zinc-300 shadow-md md:mx-0"
    >
      <section
        v-if="
          ['challenges', 'area-challenges'].includes(
            route.params.selectedMapView,
          )
        "
      >
        <header class="mb-3 text-sm uppercase tracking-wider text-zinc-300">
          <FunnelIcon class="mr-1 inline h-4 w-4" />
          Filter
        </header>

        <div class="flex flex-col">
          <label for="provider-input" class="mb-2 text-sm">
            Provider
            <span v-if="selectedProviderName && selectedProviderName.length > 0"
              >(<span @click="selectedProviderName = ''" class="underline"
                >clear</span
              >)</span
            >
          </label>
          <input
            id="provider-input"
            list="provider-list"
            placeholder="Start typing a name or ID..."
            class="rounded-md border-2 border-zinc-700 bg-transparent p-2 text-sm !outline-none focus:border-gulf-blue-700"
            v-model="selectedProviderName"
          />
          <datalist id="provider-list">
            <option v-for="provider in PROVIDERS" :value="provider.name">
              <!-- {{ `${provider.name} (ID: ${provider.fcc_provider_id})` }} -->
              ID: {{ provider.fcc_provider_id }}
            </option>
          </datalist>
        </div>

        <div
          v-if="route.params.selectedMapView === 'challenges'"
          class="flex flex-col"
        >
          <label for="submission-id-input" class="mb-2 mt-4 text-sm">
            Submission ID
            <span
              v-if="
                submissionIdSearchString && submissionIdSearchString.length > 0
              "
              >(<span @click="submissionIdSearchString = ''" class="underline"
                >clear</span
              >)</span
            >
          </label>
          <input
            id="submission-id-input"
            type="text"
            class="rounded-md border-2 border-zinc-700 bg-transparent p-2 text-sm !outline-none focus:border-gulf-blue-700"
            placeholder="Start typing a submission ID..."
            v-model="challengeMapStore.submissionIdSearchString"
          />
        </div>
      </section>

      <section
        class="flex flex-col gap-3"
        v-if="route.params.selectedMapView === 'area-challenges'"
      >
        <header class="mb-1 text-sm uppercase tracking-wider text-zinc-300">
          <MapIcon class="mr-1 inline h-4 w-4" />
          Map Legend
        </header>

        <div class="flex flex-row gap-3 text-xs">
          <div class="h-5 min-w-5 rounded bg-[#c800ff]"></div>
          <div>1 or More Area Challenges</div>
        </div>
      </section>
    </div>
  </transition>
</template>
