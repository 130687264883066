<script setup>
import { storeToRefs } from 'pinia';
import { useCaiChallengeMapStore } from '@/store/cai-challenge-map.js';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/vue/24/solid';
import { computed, ref, watch } from 'vue';
import {
  getLabelForChallengeStatus,
  getLabelForChallengeType,
} from '@/utils/ids-to-names';

const caiChallengeMapStore = useCaiChallengeMapStore();

const {
  selectedFeature: selectedMapFeature,
  selectedLocationChallenges: challenges,
} = storeToRefs(caiChallengeMapStore);

// REFS
const currentChallengeIndex = ref(0);

// COMPUTEDS
const currentChallenge = computed(
  () => challenges.value[currentChallengeIndex.value],
);
const challengesLength = computed(() => challenges.value.length);
// TODO sort challenges by date?

// WATCHERS
// reset current challenge index when user selects a new location
watch(selectedMapFeature, () => {
  currentChallengeIndex.value = 0;
});
</script>

<template>
  <div v-if="challengesLength > 0">
    <div
      class="details-panel-background rounded-lg bg-zinc-800 p-4 shadow-md md:mb-4 md:mr-4 md:overflow-y-auto"
    >
      <section>
        <div v-if="challengesLength === 0">Loading challenges...</div>

        <div v-if="challengesLength > 0">
          <h2 class="text-lg font-semibold" v-if="challengesLength === 1">
            There is 1 challenge at this location.
          </h2>
          <h2 class="mt-6 text-lg font-semibold" v-if="challengesLength > 1">
            Showing challenge
            {{ currentChallengeIndex + 1 }} of {{ challengesLength }} at this
            location:
          </h2>

          <!-- TODO use `apply` for the align-top and any other global table styles -->
          <table class="mt-4 table-auto text-left text-sm">
            <tr class="align-top">
              <th class="py-1 pr-4">Challenge Type</th>
              <td>
                {{ getLabelForChallengeType(currentChallenge.challenge_type) }}
              </td>
            </tr>
            <tr class="align-top">
              <th class="py-1 pr-4">CAI Name</th>
              <td>
                {{ currentChallenge.entity_name }}
              </td>
            </tr>
            <tr class="align-top">
              <th class="py-1 pr-4">CAI ID</th>
              <td>
                {{ currentChallenge.cai_id }}
              </td>
            </tr>
            <tr class="align-top">
              <th class="py-1 pr-4">Address</th>
              <td>
                <span
                  v-if="
                    currentChallenge.street_address &&
                    currentChallenge.street_address.length > 0
                  "
                >
                  {{ currentChallenge.street_address }},
                  {{ currentChallenge.city }}, IL
                  {{ currentChallenge.zip_code }}
                </span>
                <span
                  v-if="
                    !currentChallenge.street_address ||
                    currentChallenge.street_address.length === 0
                  "
                  >No address</span
                >
              </td>
            </tr>
            <tr class="align-top">
              <th class="py-1 pr-4">Status</th>
              <td>
                {{ getLabelForChallengeStatus(currentChallenge.status_code) }}
              </td>
            </tr>
            <tr class="align-top">
              <th class="py-1 pr-4">Challenger</th>
              <td>{{ currentChallenge.challenger_org }}</td>
            </tr>
            <tr class="align-top">
              <th class="py-1 pr-4">Date</th>
              <td>
                {{ new Date(currentChallenge.challenge_date).toLocaleString() }}
              </td>
            </tr>
            <tr class="align-top">
              <th class="py-1 pr-4">Challenge ID</th>
              <td>{{ currentChallenge.challenge_id }}</td>
            </tr>
            <tr class="align-top">
              <th class="py-1 pr-4">Submission ID</th>
              <td>{{ currentChallenge.submission_id }}</td>
            </tr>
          </table>

          <div
            class="mt-4 flex place-content-between"
            v-if="challengesLength > 1"
          >
            <button
              class="flex items-center rounded-md bg-gulf-blue-800 px-4 py-2 text-white hover:bg-gulf-blue-600"
              @click="currentChallengeIndex--"
              :class="{ invisible: currentChallengeIndex === 0 }"
            >
              <ArrowLeftIcon class="mr-2 inline h-4 w-4" />
              Back
            </button>
            <button
              class="flex items-center rounded-md bg-gulf-blue-800 px-4 py-2 text-white hover:bg-gulf-blue-600"
              @click="currentChallengeIndex++"
              :class="{
                invisible: currentChallengeIndex === challengesLength - 1,
              }"
            >
              Next
              <ArrowRightIcon class="ml-2 inline h-4 w-4" />
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
